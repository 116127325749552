<template>
  <div class="warp">
    <div class="warp-img">
      <img class="img" src="../../assets/img/tjcg@3x.png" alt="" />
      <p>资料提交成功</p>
      <p>请耐心等待资料评估是否符合标准</p>
    </div>
    <div class="warp-btn">
      <van-button
        type="primary"
        block
        size="large"
        color="linear-gradient(to right, #BCA675, #A78A48)"
        @click="editbtn"
      >
        重新修改
      </van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
    };
  },
  methods: {
    // 重新修改
    editbtn() {
      this.$router.push({
        path: "/incomingParts",
        query: {
          data: this.data,
          id: this.id,
        },
      });
    },
  },
  created() {
    this.data = this.$route.query.data;
    this.id = this.$route.query.id;
    console.log(this.id);
  },
};
</script>

<style scoped>
.warp {
  width: 100%;
  height: 100vh;
}
.warp-img {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30%;
}
.warp-img p {
  font-size: 14px;
}
.img {
  width: 100px;
  height: 90px;
  margin-bottom: 10px;
}
.warp-btn {
  width: 55%;
  margin: auto;
  display: flex;
  justify-content: space-around;
}
</style>
